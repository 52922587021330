import api from '@apiInstance';

export default {
  add (params) {
    return api.post('modules', params);
  },
  get (id) {
    return api.fetch('modules/' + id);
  },
  list (params) {
    return api.fetch('modules', { params });
  },
  update (params) {
    return api.patch('modules', params);
  },
  delete (id) {
    return api.remove('modules', id);
  }
};
